import React, { useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import useQuickTranslator from '../../hooks/useQuickTranslator'
import NetlifiedForm from './NetlifiedForm'
import LocalizedLink from '../LocalizedLink'

/**
 * @param {string} name
 * @returns {JSX.Element}
 * @constructor
 */
const ContactForm = ({ name }) => {
    const t = useQuickTranslator()

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const submitHandler = e => {
        setError(false)
        setLoading(true)

        let formData = new FormData(e.target)

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(formData).toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setError(t('contactform.error'))
                    return
                }

                setSubmitted(true)
            })
            .catch(() => setError(t('contactform.error')))
            .finally(() => setLoading(false))
    }

    return !submitted ? (
        <>
            {error && <Alert variant='danger'>{error}</Alert>}
            <NetlifiedForm onSubmit={submitHandler} className='mb-5' name={name} subject='Contact request'>
                <Form.Group className='mb-3' controlId='name'>
                    <Form.Label visuallyHidden>
                        <FormattedMessage id='contactform.name' />
                    </Form.Label>
                    <Form.Control type='text' placeholder={t('contactform.name')} name='name' className='px-4' />
                </Form.Group>
                <Form.Group className='mb-3' controlId='phone'>
                    <Form.Label visuallyHidden>
                        <FormattedMessage id='contactform.phone' />
                    </Form.Label>
                    <Form.Control type='text' placeholder={t('contactform.phone')} name='phone' className='px-4' />
                </Form.Group>
                <Form.Group className='mb-3' controlId='email'>
                    <Form.Label visuallyHidden>
                        <FormattedMessage id='contactform.email' />
                    </Form.Label>
                    <Form.Control
                        type='email'
                        placeholder={t('contactform.email')}
                        name='email'
                        className='px-4'
                        required
                    />
                </Form.Group>
                <Form.Group className='mb-4' controlId='message'>
                    <Form.Label visuallyHidden>
                        <FormattedMessage id='contactform.message' />
                    </Form.Label>
                    <Form.Control
                        as='textarea'
                        rows={3}
                        placeholder={t('contactform.message')}
                        name='message'
                        className='px-4'
                        required
                    />
                </Form.Group>

                <Form.Group className='mb-4' controlId='accept-pp'>
                    <Form.Check type='checkbox'>
                        {/* That negative margin, to work with padding (to enlarge the checkbox), but it breaks the mobile layout on some screen scales */}
                        <Form.Check.Input
                            className='form-check-input border-secondary me-n1'
                            name='accept-pp'
                            type='checkbox'
                            required
                        />
                        <Form.Check.Label className='ms-3 text-secondary form-check-label'>
                            {t('contactform.accept_privacy_policy', {
                                pp: chunks => <LocalizedLink to='/privacy-policy'>{chunks}</LocalizedLink>,
                            })}
                        </Form.Check.Label>
                    </Form.Check>
                </Form.Group>

                <Row className='d-flex justify-content-end'>
                    <Col lg='5' className='d-grid gap-2'>
                        <Button variant='success' type='submit' disabled={loading} className='text-uppercase'>
                            <FormattedMessage id='contactform.submit' />
                        </Button>
                    </Col>
                </Row>
            </NetlifiedForm>
        </>
    ) : (
        <div className='text-center'>
            <span className='h1 mb-3 d-block'>
                <FormattedMessage id='contactform.thankyou.h' />
            </span>
            <p className='mb-4'>
                <FormattedMessage id='contactform.thankyou.p' />
            </p>
        </div>
    )
}

export default ContactForm
