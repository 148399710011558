import React from 'react'
import { Container } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import FaqItem from '../FaqItem'

/**
 * @param item
 * @param lang
 * @returns {JSX.Element}
 * @constructor
 */
const FaqCategorySection = ({ item, lang = 'en' }) => {
    const { allFaqItems } = useStaticQuery(graphql`
        query {
            allFaqItems: allSanityFaqItem(sort: { fields: weight, order: ASC }) {
                nodes {
                    question
                    _rawAnswer(resolveReferences: { maxDepth: 1 })
                    slug {
                        current
                    }
                    weight
                    category {
                        _id
                    }
                    i18n_lang
                }
            }
        }
    `)

    let faqItems = allFaqItems.nodes.filter(item => item.i18n_lang === lang)

    // Filter by category, if given
    if (item.category) {
        faqItems = faqItems.filter(faqItem => faqItem.category.some(category => category._id === item.category._id))
    }

    return (
        <section className='py-3' id={item.sectionIdSlug?.current || item._key}>
            <Container>
                <h2 className='mt-4 mb-5'>{item.title}</h2>

                {faqItems.map(item => (
                    <FaqItem
                        key={item.slug.current}
                        question={item.question}
                        answer={item._rawAnswer}
                        slug={item.slug.current}
                    />
                ))}
            </Container>
        </section>
    )
}

export default FaqCategorySection
