import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { PortableText } from '@portabletext/react'
import portableTextComponents, { PortableTextLinkOrAnchor } from '../portableTextComponents'
import cloneDeep from 'lodash/cloneDeep'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// Modify portable text components a bit for our taste here
const myPortableTextComponents = cloneDeep(portableTextComponents)
myPortableTextComponents.block.normal = ({ children }) => <p className='spaced-light mb-1'>{children}</p>
myPortableTextComponents.types.ctaButton = ({ value }) => (
    <PortableTextLinkOrAnchor
        value={value}
        className='btn btn-success btn-lg px-5 d-inline-block rounded-0 mt-4 mb-1'
    />
)

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const TextWithBgSection = ({ item }) => {
    const bgImage = getImage(item.bgImage.asset)

    return (
        <section
            id={item.sectionIdSlug?.current || item._key}
            className='darker-background-overlay text-white text-center position-relative'>
            <div className='d-flex'>
                <Container className='py-5'>
                    <Row className='py-4 justify-content-center'>
                        <Col md='10'>
                            <PortableText value={item._rawContent} components={myPortableTextComponents} />
                        </Col>
                    </Row>
                </Container>
                <GatsbyImage
                    className='z-index-n1 position-absolute w-100 h-100'
                    alt=''
                    image={bgImage}
                    role='presentation'
                    placeholder='blurred'
                    loading='lazy'
                />
            </div>
        </section>
    )
}

export default TextWithBgSection
