import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import portableTextComponents from '../portableTextComponents'
import cloneDeep from 'lodash/cloneDeep'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PortableText } from '@portabletext/react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

// Add icons for global usage
library.add(fas)

// Modify portable text components a bit for our taste here
const myPortableTextComponents = cloneDeep(portableTextComponents)
myPortableTextComponents.block.h1 = ({ children }) => <p className='spaced-light mb-2'>{children}</p>

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const FeaturesSection = ({ item }) => {
    return (
        <section id={item.sectionIdSlug?.current || item._key} className='pt-5 pb-4'>
            <Container>
                <Row>
                    <Col lg={{ span: 10, offset: 1 }}>
                        <h2>{item.title}</h2>
                        <Row className='mb-3'>
                            {item.items.map(item => {
                                const icon = [item.icon.provider, item.icon.name]
                                return (
                                    <Col key={item._key} md='6' className='mb-3'>
                                        <Row>
                                            <Col xs='2' className='pt-1 ps-4'>
                                                <FontAwesomeIcon icon={icon} size='lg' />
                                            </Col>
                                            <Col xs='10' className='ps-0'>
                                                <PortableText
                                                    value={item._rawValue}
                                                    components={myPortableTextComponents}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default FeaturesSection
