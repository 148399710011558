import React, { useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { PortableText } from '@portabletext/react'
import portableTextComponents, { PortableTextLinkOrAnchor } from '../portableTextComponents'
import cloneDeep from 'lodash/cloneDeep'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// Modify portable text components a bit for our taste here
const myPortableTextComponents = cloneDeep(portableTextComponents)
myPortableTextComponents.block.h1 = ({ children }) => <h1 className='name mb-4 text-uppercase'>{children}</h1>
myPortableTextComponents.block.normal = ({ children }) => <p className='message mb-5'>{children}</p>
myPortableTextComponents.types.ctaButton = ({ value }) => (
    <PortableTextLinkOrAnchor value={value} className='btn btn-success btn-lg px-5 d-inline-block rounded-0 mb-5' />
)

/**
 * @param item
 * @param isFirstComponent
 * @returns {JSX.Element}
 * @constructor
 */
const FullScreenHeroSection = ({ item, isFirstComponent = true }) => {
    const bgImage = getImage(item.bgImage.asset)

    // We want to stay semantic, and only have one h1 element on the page. So, if this is not the first
    // component rendered on the page, render the heading as h2, but styled as h1.
    const portableTextComponents = useMemo(() => {
        const components = cloneDeep(myPortableTextComponents)
        if (!isFirstComponent) {
            components.block.h1 = ({ children }) => <h2 className='h1 name mb-4 text-uppercase'>{children}</h2>
        }
        return components
    }, [isFirstComponent])

    return (
        <section
            id={item.sectionIdSlug?.current || item._key}
            className='darker-background-overlay py-2 text-white position-relative'>
            <div className='d-flex align-items-center full-screen-jumbo'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg='8' md='10'>
                            <Row className={`${item.justifyContent} ${item.textAlign}`}>
                                <Col xl='6' lg='8' sm='9'>
                                    <PortableText value={item._rawContent} components={portableTextComponents} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <GatsbyImage
                    className='z-index-n1 position-absolute w-100 h-100'
                    alt=''
                    image={bgImage}
                    role='presentation'
                    placeholder='blurred'
                    loading={isFirstComponent ? 'eager' : 'lazy'}
                />
            </div>
        </section>
    )
}

export default FullScreenHeroSection
