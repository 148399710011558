import React from 'react'
import styled from 'styled-components'

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const DemoIFrameSection = ({ item }) => {
    return (
        <StyledDemoSection className='pb-5X pt-3X'>
            <iframe title={item.title} src={item.src} className='w-100 border-0 demo-iframe' />
        </StyledDemoSection>
    )
}

export default DemoIFrameSection

const StyledDemoSection = styled.section`
    height: clamp(29em, 100vh, 44em);
    overflow: hidden;

    .demo-iframe {
        min-height: clamp(35em, 117vh, 50em);
    }
`
