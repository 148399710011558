import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container } from 'react-bootstrap'
import PostListing from '../blog/PostListing'
import { FormattedMessage } from 'react-intl'

/**
 * @param item
 * @param lang
 * @returns {JSX.Element}
 * @constructor
 */
const BlogPostListSection = ({ item, lang = 'en' }) => {
    const { allBlogPostArticles } = useStaticQuery(graphql`
        query {
            allBlogPostArticles: allSanityBlogPost(sort: { fields: publishedAt, order: DESC }) {
                nodes {
                    title
                    _rawBody
                    slug {
                        current
                    }
                    lang
                    mainImage {
                        alt
                        image {
                            asset {
                                gatsbyImageData(placeholder: BLURRED, width: 100, aspectRatio: 1)
                            }
                        }
                    }
                }
            }
        }
    `)

    // Limit item count if necessary
    let postsToDisplay = allBlogPostArticles.nodes.filter(item => item.lang === lang)

    if (item.amountOfPostsToDisplay) {
        postsToDisplay = postsToDisplay.slice(0, item.amountOfPostsToDisplay)
    }

    return postsToDisplay.length === 0 ? null : (
        <section className='py-5'>
            <Container className='mt-4 mb-5'>
                <h2>
                    <FormattedMessage id='blog.latest' />
                </h2>
                <PostListing related={postsToDisplay} truncateExcerptTo={230} />
            </Container>
        </section>
    )
}

export default BlogPostListSection
